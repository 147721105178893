<template>
	<div class="equipmentLine">
		<el-table ref="table" :data="tableData" lazy style="width: 100%; margin-bottom: 20px" row-key="id" border
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :load="load" v-loading="loading"
			element-loading-text="正在加载中" @row-click="expandChange" element-loading-spinner="el-icon-loading">
			<el-table-column prop="name" label="设备树" min-width="30%">
				<template slot-scope="scope">
					<img class="equTree-img" :src="cellData(scope).imageSrc" v-if="cellData(scope).imageSrc !== ''">
					<span class="equTree-span">{{ cellData(scope).name }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="type" label="类型" min-width="7%">
				<template slot-scope="scope">
					<div>{{ typeList[scope.row.type] }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" min-width="7%" sortable>
			</el-table-column>
			<el-table-column prop="level" label="等级" min-width="7%">
			</el-table-column>
			<el-table-column prop="remarks" label="备注" min-width="10%"> </el-table-column>
			<el-table-column fixed="right" label="操作" min-width="40%" style="padding:0">
				<template slot-scope="scope">
					<div class="operationBtn" v-if="scope.row.type == 0">
						<el-button type="text" size="small" @click="insert('添加组织', scope.row, 10)">添加组织</el-button>
						<el-button type="text" size="small" @click="insert('添加设备', scope.row, 100)">添加设备</el-button>
						<el-button type="text" size="small" @click="edit('编辑设备树', scope.row)">编辑</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树', scope.row)">删除</el-button>
						<el-upload ref="upload" style="display: inline-block;margin:0 10px;" action=""
							:on-change="handleChange" :show-file-list="false" :file-list="fileList" :limit="1"
							accept=".xls,.xlsx" :auto-upload="false">
							<el-button type="text" size="small" @click="setEntId(scope.row, 1)">导入excel</el-button>
						</el-upload>
						<el-button type="text" size="small" @click="download1(scope.row)">下载数据</el-button>

					</div>
					<div class="operationBtn" v-if="scope.row.type == 10">
						<el-button type="text" size="small" @click="insert('添加组织', scope.row, 10)">添加组织</el-button>
						<el-button type="text" size="small" @click="insert('添加设备', scope.row, 100)">添加设备</el-button>
						<el-button type="text" size="small" @click="glzx('关联整线', scope.row)">关联整线</el-button>
						<el-button type="text" size="small" @click="edit('编辑设备树', scope.row)">编辑</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树', scope.row)">删除</el-button>
						<el-upload ref="upload1" v-if="scope.row.level == 3"
							style="display: inline-block;margin:0 10px;" action="" :on-change="handleChange2"
							:show-file-list="false" :file-list="fileList2" :limit="1" accept=".xls,.xlsx"
							:auto-upload="false">
							<el-button type="text" size="small" style="color: #58bc58"
								@click="setEntId(scope.row, 2)">导入(无台套)</el-button>
						</el-upload>
						<el-upload ref="upload1" v-if="scope.row.level == 3"
							style="display: inline-block;margin:0 10px;" action="" :on-change="handleChange3"
							:show-file-list="false" :file-list="fileList3" :limit="1" accept=".xls,.xlsx"
							:auto-upload="false">
							<el-button type="text" size="small" style="color: #58bc58"
								@click="setEntId(scope.row, 4)">导入数据</el-button>
						</el-upload>
						
						<el-button v-if="scope.row.ent_id == '12c3dc91b6644961ffe3ad91ecdb6539' && scope.row.level == 1"
							type="text" size="small" style="color: #58bc58"
							@click="setEntId(scope.row, 3)">绑定(智寰)</el-button>
						<!-- <el-button v-if="scope.row.ent_id == '12c3dc91b6644961ffe3ad91ecdb6539' && scope.row.level == 1" type="text" size="small" @click="download()">下载模板</el-button> -->
						<el-button v-if="scope.row.level == 3" type="text" size="small" @click="download2(scope.row)"
							style="color: #58bc58">下载(无台套)</el-button>
						<el-button v-if="scope.row.level == 3" type="text" size="small" @click="download(scope.row)"
							style="color: #58bc58">下载数据</el-button>
						<el-button v-if="scope.row.level == 3" type="text" size="small" @click="download3(scope.row)"
							style="color: #ffaa00">下载模板</el-button>


					</div>
					<div class="operationBtn" v-else-if="scope.row.type == 100">
						<el-button v-for="button in buttons" :key="button.text" type="text" size="small"
							@click="insert(button.text, scope.row, button.param)">
							{{ button.text }}
						</el-button>
						<el-button type="text" size="small" @click="edit('编辑', scope.row)">编辑</el-button>
						<el-button type="text" size="small" @click="equInfo('设备信息', scope.row)">设备信息</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树', scope.row)">删除</el-button>
					</div>
					<div class="operationBtn" v-else-if="scope.row.type >= 1000 && scope.row.type < 10000">
						<el-button type="text" size="small" @click="clinit('测量定义', scope.row, 10000)"
							v-if="scope.row.type == 1000">测量定义</el-button>
						<el-button type="text" size="small" @click="edit('编辑', scope.row, 1000)">编辑</el-button>
						<el-button type="text" size="small" @click="gldt('关联单体', scope.row)"
							v-if="scope.row.type == 1001||scope.row.type == 1004">
							关联单体</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树', scope.row)">删除</el-button>
					</div>
					<div class="operationBtn" v-else-if="scope.row.type == 10000">
						<el-button type="text" size="small" @click="deleteDef('删除设备树', scope.row)">删除</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>此操作改动较大，是否继续？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose()">取 消</el-button>
				<el-button type="primary" @click="setUploadExcel(dialogNumber)">确 定</el-button>
			</span>
		</el-dialog>

		<edit2 :isShow.sync="showEditDialog2" :dialogObj="editObj2"></edit2>

	</div>
</template>


<script>
	import edit from "./edit.vue"
	import edit2 from "./edit2.vue"

	export default {
		components: {
			edit,
			edit2
		},
		data() {
			return {
				tableData: [],
				showEditDialog: false,
				editObj: {},
				showEditDialog2: false,
				editObj2: {},
				ents: [],
				currentEnt: {},
				tableDataCopy: [],
				newFile: new FormData(),
				newFile2: new FormData(),
				newFile3: new FormData(),
				fileList: [],
				fileList2: [],
				fileList3: [],
				parentId: '',
				entId: '',
				loading: false,
				dialogVisible: false,
				dialogNumber: '',
				typeList: {
					0: '企业',
					10: '组织',
					100: '设备',
					1000: '振动',
					1001: '转速',
					1002: '温度',
					1003: '油液',
					1004: 'wincc',
					1006: '湿度',
					1007: '振动(瑞邦)',
					10000: '测量定义',
				},
				buttons: [{
						text: '添加设备',
						param: 100
					},
					{
						text: '添加振动测点',
						param: 1000
					},
					{
						text: '添加转速测点',
						param: 1001
					},
					{
						text: '添加温度测点',
						param: 1002
					},
					{
						text: '添加油液测点',
						param: 1003
					},
					{
						text: '添加wincc转速测点',
						param: 1004
					},
					{
						text: '添加湿度测点',
						param: 1006
					},
					{
						text: '添加振动测点(瑞邦)',
						param: 1007
					},
				],
				treeData: null,
			};
		},
		computed: {
			cellData() {
				return (scope) => {
					const {
						type,
						level,
						name
					} = scope.row;
					let imageSrc = "";
					const typeToImageSrc = {
						0: require("@assets/osImg/equipmentLine/qiye.png"),
						10: require("@assets/osImg/equipmentLine/zhuzhi.png"),
						100: require("@assets/osImg/equipmentLine/shebei.png"),
						1000: require("@assets/osImg/equipmentLine/zhendong.png"),
						1001: require("@assets/osImg/equipmentLine/zhuansu.png"),
						1002: require("@assets/osImg/equipmentLine/wendu.png"),
						1003: require("@assets/osImg/equipmentLine/youye.png"),
						1006: require("@assets/osImg/equipmentLine/wendu.png"),
						1007: require("@assets/osImg/equipmentLine/zhendong.png"),
						10000: require("@assets/osImg/equipmentLine/jiasudu.png")
					};

					imageSrc = typeToImageSrc[type];
					return {
						imageSrc,
						name,
					};
				};
			},
		},
		methods: {
			//点击某一行的回调事件
			expandChange(row, index, e) {
				this.parent_id = row.parent_id;
				// if (index.label == '设备树') {
				// 	this.$refs.table.toggleRowExpansion(row)
				// }
			},
			handleClose() {
				this.dialogVisible = false;
				this.newFile = new FormData();
				this.newFile2 = new FormData();
				this.newFile3 = new FormData();
				this.fileList = [];
				this.fileList2 = [];
				this.fileList3 = [];

			},
			setEntId(e, index) {
				
				this.dialogNumber = index;
				switch (index) {
				    case 1:
				        this.entId = e.ent_id

				        break;
				    case 2:
				        this.parentId = e.id

				        break;
				    case 3:
						this.showEditDialog2 = true;
						this.editObj2 = e;

				        break;
					case 4:
					    this.parentId = e.id
					
					    break;
				}
			},
			// 提取通用的上传前校验逻辑到一个函数中
			handleFileUpload(file, fileList, targetList, targetFile, targetId) {
				if (targetList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType !== '.xls' && fileType !== '.xlsx') {
					this.$message.error('上传文件只能是 excel 文件格式!');
				} else if (file) {
					if (targetFile.has("file")) {
						targetFile.delete("file");
					}
					targetFile.append('file', file.raw);
					targetFile.append(targetId, this[targetId]);
					this.dialogVisible = true;
				}
			},
			// 使用提取的函数来处理每个上传事件
			handleChange(file, fileList) {
				this.handleFileUpload(file, fileList, this.fileList, this.newFile, "entId");
			},
			handleChange2(file, fileList) {
				this.handleFileUpload(file, fileList, this.fileList2, this.newFile2, "parentId");
			},
			handleChange3(file, fileList) {
				this.handleFileUpload(file, fileList, this.fileList3, this.newFile3, "parentId");
			},

			// 提取通用的上传函数
			uploadExcel(apiPath, fileList, newFile, targetList) {
				this.dialogVisible = false;
				this.loading = true;
				this.$postData(apiPath, newFile).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						newFile = new FormData();
						targetList = [];
						this.$message.success(res.msg);
						this.list(0);
					} else {
						this.loading = false;
						newFile = new FormData();
						targetList = [];
						this.$message.error(res.msg);
					}
				});
			},

			// 使用提取的函数来处理每个上传事件
			setUploadExcel(e) {
				switch (e) {
					case 1:
						this.uploadExcel("/backend-api/eqp/set/excel/save", this.fileList, this.newFile, this.fileList);
						break;
					case 2:
						this.uploadExcel("/backend-api/eqp/set/excelArea/save", this.fileList2, this.newFile2, this
							.fileList2);

						break;
					case 3:
						// this.newFile3.append('entId', '12c3dc91b6644961ffe3ad91ecdb6539');
						// this.uploadExcel("/backend-api/eqp/set/unit/meas/excelGoyoZhihuanAutoAddBanding/save", this
						// 	.fileList3, this.newFile3, this.fileList3);
						break;
					case 4:
						this.uploadExcel("/backend-api/eqp/set/excelArea/saveV1", this.fileList3, this.newFile3, this
							.fileList3);
					
						break;
				}
			},

			// 通用的下载方法
			downloadFile(apiUrl, params, fileName) {
				this.$download(apiUrl, params).then(res => {
					const blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.download = fileName;
					link.href = url;
					document.body.appendChild(link);
					link.click();
				});
			},

			download(item) {
				// let url = 'https://minio.smheee.com/smkj/%E8%AE%BE%E5%A4%87%E6%95%B0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
				//          let url = 'https://minio.smheee.com/excel/汉威广园测点智寰自动添加绑定.xlsx'
				// window.open(
				//              url,
				//              '_self'
				//          );

				this.downloadFile(
				    '/backend-api/eqp/set/exportEqpSetAreaListV1',
				    { parentId: item.id },
				    item.name + '.xlsx'
				);
			},

			download1(item) {
				this.downloadFile(
					'/backend-api/eqp/set/exportEqpSetList', {
						entId: item.ent_id
					},
					item.name + '.xlsx'
				);
			},

			download2(item) {
				this.downloadFile(
					'/backend-api/eqp/set/exportEqpSetAreaList', {
						parentId: item.id
					},
					item.name + '.xlsx'
				);
			},

			download3(item) {
				this.downloadFile(
					'/backend-api/eqp/set/exportEqpSetAreaListModel', {
						parentId: item.id
					},
					item.name + '（自动生成ID）.xlsx'
				);
			},


			initCurrentEnt(entId) {
				for (var item of this.ents) {
					if (item.id == entId) {
						this.currentEnt = {
							id: item.id,
							name: item.title
						};
						break;
					}
				}
			},
			insert(title, row, type) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "insert",
					title: title,
					row: row,
					type: type,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			edit(title, row, type) {
				if (row.level == 0) {
					this.$message.warning("根路径不能编辑");
					return;
				}
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "edit",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				if (type) {
					this.editObj.type = type;
				}
				this.showEditDialog = true;
			},
			glzx(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "glzx",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;

			},
			equInfo(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "equInfo",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			cdInfo(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "cdInfo",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			clinit(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "clinit",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			gldt(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "gldt",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			deleteOne(title, row) {
				if (row.level == 0) {
					this.$message.warning("根路径不能删除");
					return;
				}
				this.initCurrentEnt(row.ent_id);
				var context = "企业：" + this.currentEnt.name + "<br/>";
				context += "设备树：" + row.path + "<br/>"
				context += "是否删除？";
				this.$confirm(context, title, {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$remove("/backend-api/eqp/set/remove/" + row.id, {
						parent_id: row.parent_id,
						ent_id: row.ent_id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list(1);
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			deleteDef(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.$confirm("是否删除测量定义", title, {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$remove("/backend-api/eqp/set/unit/meas/cancelAuth/" + row.id, {
						parent_id: row.parent_id,
						ent_id: row.ent_id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list(1);
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			list(e) {
				this.$get("/backend-api/eqp/set/list").then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data.list
						this.tableDataCopy = res.data.list || [] // 备份的全量数据
						if (this.treeData) {
							const node = this.findNodeById(this.tableDataCopy, this.treeData.id);
							this.$set(this.$refs.table.store.states.lazyTreeNodeMap, this.treeData.id, node
								.children);
						}
						this.tableData = JSON.parse(JSON.stringify(res.data.list)).map(item => { // 展示数据
							// hasChildren 表示需要展示一个箭头图标
							item.hasChildren = item.children && item.children.length > 0
							// 只展示一层
							// 如果有children数据，会自动加载，就不是懒加载了，也可以配置tree-props里面的children为其他字段
							item.children = null
							// 记住层级关系
							item.idList = [item.id]
							return item
						})
						this.ents = res.data.ents;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			findNodeById(tree, id) {
				for (const node of tree) {
					if (node.id === id) {
						return node;
					}
					if (node.children && node.children.length > 0) {
						const found = this.findNodeById(node.children, id);
						if (found) {
							return found;
						}
					}
				}
				return null;
			},
			// 定义一个名为load的函数，用于懒加载树结构数据
			load(tree, treeNode, resolve) {

				// 使用JSON序列化与反序列化的方式，深拷贝tree对象中的idList，作为层级关系备份
				const idCopy = JSON.parse(JSON.stringify(tree.idList));
				// 初始化处理下一层数据的数组为tableDataCopy的拷贝
				let resolveArr = this.tableDataCopy;
				// 创建变量id，用于循环中逐个处理idList中的元素
				let id;
				// 使用while循环，按顺序移除并处理tree.idList中的id
				while (id = tree.idList.shift()) {
					// 在resolveArr中查找与当前id匹配的节点对象
					const tarItem = resolveArr.find(item => item.id === id);
					// 标记查找到的节点对象已加载其子节点
					tarItem.loadedChildren = true;
					// 更新resolveArr为查找到节点的子节点列表，以便处理下一层数据
					resolveArr = tarItem.children;
				}
				// 对处理后的下一层数据进行深拷贝，确保不影响原始数据
				resolveArr = JSON.parse(JSON.stringify(resolveArr));
				// 遍历处理后的下一层数据，为每个节点设置hasChildren属性并清空children
				// 同时备份层级关系并添加当前节点id到idList
				resolveArr.forEach(item => {
					item.hasChildren = item.children && item.children.length > 0; // 判断是否有子节点
					item.children = null; // 清空子节点数据，为懒加载做准备
					item.idList = JSON.parse(JSON.stringify(idCopy)); // 为节点备份层级关系
					item.idList.push(item.id); // 添加当前节点id到备份的层级关系中
				});
				// 标记当前正在处理的树节点（tree）已加载其子节点
				tree.loadedChildren = true;
				// 调用resolve函数，将处理后的子节点数据作为参数传入，完成懒加载过程
				resolve(resolveArr);
				// 将当前操作涉及的树结构记录到treeData中
				this.treeData = tree;
			},




			unload() {
				this.showTable = false
				this.$nextTick(() => this.showTable = true)
				this.tableData = JSON.parse(JSON.stringify(this.tableDataCopy)).map(item => {
					// hasChildren 表示需要展示一个箭头图标
					item.hasChildren = item.children && item.children.length > 0
					// 只展示一层
					item.children = null
					// 记住层级关系
					item.idList = [item.id]
					return item
				})
			},
		},
		mounted() {
			this.list(0);
		}
	};
</script>

<style lang='scss' scoped>
	@import "@style/userCenter.scss";

	.equipmentLine {
		background-color: #f5f5f5;
	}


	.operationBtn {
		line-height: 33px;
	}

	.equTree-img {
		height: 16px;
		width: 16px;
		vertical-align: middle;
		margin-right: 3px;
	}

	.equTree-span {
		vertical-align: middle;
	}
</style>