<template>
	<el-dialog class="dialog" title="快捷新增绑定智寰测点" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<div style="padding:10px 20px;">
				<el-button type="primary" size="small" @click="addRow">新增一行</el-button>
			</div>
			<el-table :data="list" style="width: 100%;padding: 10px;" stripe>
				<el-table-column label="序号" align="center" width="80">
					<template slot-scope="scope">
						{{scope.$index+1}}
					</template>
				</el-table-column>
				<el-table-column label="设备名称" align="center">
					<template slot-scope="scope">
						<el-input v-model="scope.row.unitName" placeholder="请输入设备名称"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="测点名称" align="center">
					<template slot-scope="scope">
						<el-input v-model="scope.row.measName" placeholder="请输入单体名称"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="智寰测点ID" align="center">
					<template slot-scope="scope">
						<el-cascader v-model="scope.row.zhihuanId" :options="treeData" :props="{
								value: 'id',
								label: 'name',
								children: 'children',
								emitPath: false
							}" @change="handleChange"></el-cascader>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button type="text" size="small" v-if="list.length>1"
							@click="deletes(scope.$index)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button size="medium" @click="close()">关闭</el-button>
			<el-button type="primary" size="medium" @click="submit()">提交</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		components: {
		},
		data() {
			return {
				loading: false,
				visiable: false,
				formData: {},
				list: [{
					unitName: '',
					measName: '',
					zhihuanId: 16
				}],
				treeData: [],
				param:{}

			};
		},
		props: {
			dialogObj: Object
		},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.visiable = true;
				this.param.entId = this.dialogObj.ent_id;
				this.param.parentId = this.dialogObj.id;
				this.$get('/backend-api/eqp/set/unit/meas/zhihuan/zhihuan/sensor/list').then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					let arr = []
					res.data.forEach(item => {
						if (item.id == 2) {
							arr = item.children
						}
					})
					this.treeData = arr
				});
			},
			handleChange(value) {
				console.log(value)
			},
			//新增一行
			addRow() {
				let obj = {
					unitName: '',
					measName: '',
					zhId: ''
				}
				this.list.push(obj)
			},
			//删除当前行
			deletes(index) {
				this.list.splice(index, 1)

			},

			submit() {
				const hasDuplicates = hasDuplicateSensorIds(this.list);
				if (hasDuplicates) {
					this.$message({
						message: '警告哦，重复选择智寰测点',
						type: 'warning'
					});
				} else {
					
					
					this.param.measList = this.list;
					
					
					this.$postJson('/backend-api/eqp/set/unit/meas/goyoZhihuanAutoAddBanding/save',this.param).then(res => {
						if (res.code != 1000) {
							this.$message({
								message: res.msg,
								type: 'error'
							});
							return
						}
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.visiable = false;
					})
				}

			},
			//关闭弹窗
			close() {
				this.visiable = false;
			}
		}
	};

	function hasDuplicateSensorIds(data) {
		const sensorIds = data.map(item => item.zhihuanId);
		const uniqueSensorIds = new Set(sensorIds);
		return sensorIds.length !== uniqueSensorIds.size;
	}
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep .cascader {
			display: block;
		}
	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep input[type='number'] {
		-moz-appearance: textfield;
	}
</style>